import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import './Galerie.css';
import { Helmet } from 'react-helmet';

function Galerie({ cookiesAccepted }) {

  const [isBlurred, setIsBlurred] = useState(!cookiesAccepted);

  // Update isBlurred when cookiesAccepted changes
  useEffect(() => {
    setIsBlurred(!cookiesAccepted);
  }, [cookiesAccepted]);
  
  return (
    <>
      <Helmet>
        <title>Galerie | Sen Physiotherapie</title>
      </Helmet>
      <Navbar isBlurred={isBlurred} />
    
      {/* Apply the blurred class conditionally */}
      <div className={`galerie-container ${isBlurred ? 'blurred' : ''}`}>
        {/* "Coming Soon" Message and Placeholder */}
        <div className="coming-soon-section">
          <h2>Galerie</h2>
          <p>Bilder kommen bald! Wir arbeiten daran, unsere Galerie mit Inhalten zu füllen. Schauen Sie bald wieder vorbei!</p>

          {/* Optional: A placeholder image or icon */}
          <div className="placeholder">
            <i className="fas fa-camera"></i> {/* Font Awesome camera icon */}
          </div>
        </div>
      </div>
      
      <Footer isBlurred={isBlurred}/>
    </>
  );
}

export default Galerie;
