import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer'; // Assuming you have this component
import Navbar from '../components/Navbar'; // Adjust the import path as needed
import './Impressum.css'; // If you have specific styles for this page
import { Helmet } from 'react-helmet';

function Impressum({cookiesAccepted}) { 

  const [isBlurred, setIsBlurred] = useState(!cookiesAccepted);

  // Update isBlurred when cookiesAccepted changes
  useEffect(() => {
    setIsBlurred(!cookiesAccepted);
  }, [cookiesAccepted]);

  return (
    <><Helmet>
      <title>Impressum | Sen Physiotherapie</title>
    </Helmet><Navbar isBlurred={isBlurred} /><div className={`impressum-container ${isBlurred ? 'blurred' : ''}`}>

    <h2 className="impressum-info-title">Impressum</h2>
        <div className="impressum-text-container">
          <div className="impressum-main-text">
            <p>
              Benjamin Sen<br />
              Sen Physiotherapie<br />
              Durlacherstr. 1<br />
              75172 Pforzheim
            </p>

            <h2>Kontakt</h2>
            <p>
              Telefon: 07231 6032819<br />
              E-Mail: info@senphysio.de
            </p>

            <h2>Berufsbezeichnung</h2>
            <p>
              Berufsbezeichnung:<br />
              Physiotherapeut
            </p>
            <p>
              Verliehen in:<br />
              Deutschland
            </p>

            <h2>Berufsrechtliche Regelungen</h2>
            <p>Die beruflichen Vorschriften für staatlich anerkannte Physiotherapeuten sind im Gesetz über die Berufe in der Physiotherapie, auch bekannt als Masseur- und Physiotherapeutengesetz (MPhG), geregelt, das am 26. Mai 1994 in Kraft trat: <br /><a href="https://www.gesetze-im-internet.de/mphg/BJNR108400994.html" target="_blank" rel="noopener noreferrer">https://www.gesetze-im-internet.de/mphg/BJNR108400994.html</a></p>

            <h2>Angaben zur Berufs­haftpflicht­versicherung</h2>
            <p>
              <strong>Name und Sitz des Versicherers:</strong><br />
              AXA Konzern AG<br />
              <br />
              AXA Konzern Aktiengesellschaft<br />
              Colonia-Allee 10-20<br />
              51067 Köln<br />
              Postanschrift: 51171 Köln
            </p>
            <p>
              <strong>Geltungsraum der Versicherung:</strong><br />
              EU
            </p>

            <h2>EU-Streitschlichtung</h2>
            <p>
              Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br />
              Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </p>

            <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
            <p>
              Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            </p>

            <p>
              Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a>
            </p>
          </div>
        </div>

        <Footer isBlurred={isBlurred} />
      </div></>
  );
}

export default Impressum;
