import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer'; // Assuming you have this component
import Navbar from '../components/Navbar'; // Adjust the import path as needed
import './Datenschutz.css'; // If you have specific styles for this page
import { Helmet } from 'react-helmet';

function Datenschutz({cookiesAccepted}) {

  const [isBlurred, setIsBlurred] = useState(!cookiesAccepted);

  // Update isBlurred when cookiesAccepted changes
  useEffect(() => {
    setIsBlurred(!cookiesAccepted);
  }, [cookiesAccepted]);

  return (
    <> 
    <Helmet>
        <title>Datenschutz | Sen Physiotherapie</title>
      </Helmet>
    <Navbar isBlurred={isBlurred}/>
    
    <div className={`datenschutz-container ${isBlurred ? 'blurred' : ''}`}>
    <h2 className="datenschutz-info-title">Datenschutzerklärung</h2>
      <div className="datenschutz-main-text">


        <h2>1. Datenschutz auf einen Blick</h2>
        <h3>Allgemeine Hinweise</h3>
        <p>Die folgenden Hinweise geben einen Überblick darüber, wie mit Ihren personenbezogenen Daten umgegangen wird, wenn Sie unsere Website besuchen. Personenbezogene Daten sind solche, die Sie persönlich identifizieren können. Weitere detaillierte Informationen finden Sie in unserer Datenschutzerklärung, die unter diesem Text aufgeführt ist.</p>
        <h3>Verwendung von Cookies</h3>
        <p>
            Unsere Website verwendet Cookies, um die Benutzerfreundlichkeit zu verbessern und verschiedene Funktionen zu ermöglichen. Diese Cookies werden ausschließlich mit Ihrer Einwilligung gesetzt und können über unsere Cookie-Einstellungen verwaltet werden.
        </p>
        <p>
            Insbesondere setzen wir Cookies von Google, die durch die Integration von Google Fonts und Google Maps auf unserer Website erstellt werden. Diese Cookies helfen uns, eine ansprechende Benutzererfahrung zu gewährleisten und die Funktionalität der Website zu verbessern.
        </p>
        <p>
            Die folgenden Cookies von Google können gesetzt werden:
        </p>
        <ul>
            <li className='datenschutz-list-elem'><strong>Cookies von Google Fonts:</strong> Diese Cookies ermöglichen ein schnelles und effizientes Laden der Schriftarten, was die Darstellung unserer Website optimiert.</li>
            <li className='datenschutz-list-elem'><strong>Cookies von Google Maps:</strong> Diese Cookies unterstützen die Nutzung interaktiver Karten und Wegbeschreibungen auf unserer Website.</li>
        </ul>
        <br />
        <p>
            Die genauen Namen der Cookies und ihre spezifischen Zwecke können variieren. Für detaillierte Informationen zur Datenverarbeitung durch Google besuchen Sie bitte die <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Datenschutzerklärung von Google</a>.
        </p>

        
        <h3>Verantwortlicher für die Datenerfassung</h3>
        <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Die Kontaktdaten finden Sie im Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung.</p>
        <h3>Erfassung Ihrer Daten</h3>
        <p>Bei Nutzung unseres Kontaktformulars werden die Daten erfasst, die Sie uns übermitteln, einschließlich Ihres Namens, Ihrer E-Mail-Adresse, Telefonnummer und Nachricht.</p>
        <p>Weitere Daten, wie technische Informationen (z. B. Internetbrowser, Betriebssystem, Uhrzeit des Seitenaufrufs), werden automatisch durch unsere IT-Systeme erfasst, sobald Sie die Website betreten.</p>
        
        
        <h3>Verwendung Ihrer Daten</h3>
        <p>Wir nutzen die gesammelten Daten zur Gewährleistung einer fehlerfreien Bereitstellung der Website und zur Kommunikation mit Ihnen. Nachrichten, die Sie über das Kontaktformular and uns übermitteln, werden verwendet, um mit Ihnen in Kontakt zu treten und Ihre Anfrage zu bearbeiten. Diese Daten werden sicher über unseren Hosting-Provider Netlify an unsere angegebene E-Mail-Adresse weitergeleitet. Weitere Informationen zur Datenverarbeitung durch Netlify finden Sie im Abschnitt „Hosting über Netlify“.</p>
        <h3>Rechte bezüglich Ihrer Daten</h3>
        <p>Sie haben das Recht, jederzeit Auskunft über Ihre gespeicherten personenbezogenen Daten zu erhalten, diese zu berichtigen oder löschen zu lassen. Sie können Ihre Einwilligung zur Datenverarbeitung jederzeit widerrufen und unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer Daten verlangen. Sie haben auch das Recht, sich bei der zuständigen Aufsichtsbehörde zu beschweren.</p>
        <p>Für Fragen zum Datenschutz können Sie sich jederzeit an uns wenden.</p>

        <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
        <h3>Datenschutz</h3>
        <p>Wir nehmen den Schutz Ihrer Daten sehr ernst und behandeln Ihre personenbezogenen Daten vertraulich gemäß den gesetzlichen Datenschutzvorschriften und dieser Datenschutzerklärung. Beim Besuch unserer Website werden verschiedene personenbezogene Daten erhoben. Diese Datenschutzerklärung erläutert, welche Daten erfasst werden, wie sie verwendet werden und wie der Schutz Ihrer Daten gewährleistet wird.</p>
        <p>Bitte beachten Sie, dass Datenübertragungen im Internet (z. B. bei der E-Mail-Kommunikation) Sicherheitslücken aufweisen können. Ein vollständiger Schutz vor Zugriff durch Dritte kann nicht gewährleistet werden.</p>
        <h3>Hinweis zur verantwortlichen Stelle</h3>
        <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
        <p>Sen Physiotherapie<br /> Durlacherstr. 1<br /> 75172 Pforzheim<br /> Geschäftsführer: Benjamin Sen</p>
        <p>Telefon: 07231/6032819<br /> E-Mail: info@senphysio.de</p>
        <h3>Speicherdauer</h3>
        <p>Sofern in dieser Datenschutzerklärung keine spezifische Speicherdauer genannt ist, bleiben Ihre personenbezogenen Daten bei uns gespeichert, bis der Zweck der Verarbeitung entfällt. Bei berechtigtem Löschersuchen oder Widerruf der Einwilligung zur Datenverarbeitung werden Ihre Daten gelöscht, es sei denn, gesetzliche Aufbewahrungsfristen bestehen.</p>
        <h3>Rechtsgrundlagen der Datenverarbeitung</h3>
        <p>Wir verarbeiten Ihre Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) oder Art. 6 Abs. 1 lit. b DSGVO (Vertragserfüllung). Datenverarbeitung aufgrund von rechtlichen Verpflichtungen erfolgt gemäß Art. 6 Abs. 1 lit. c DSGVO. Weitere Details zur Rechtsgrundlage werden in den folgenden Abschnitten erläutert.</p>
        


        <h2>3. Hosting über Netlify</h2>
        <p>Wir nutzen den Webhosting-Dienstleister Netlify für unsere Website. Das Unternehmen hat seinen Sitz in den USA:</p>
        <p>Netlify, Inc., 610 22nd Street, Suite 315, San Francisco, CA 94107, USA</p>
        <p>Bei Ihrem Besuch unserer Website stellt Ihr Browser eine Verbindung zu Netlify-Servern her. Dabei werden Verbindungsdaten in Logdateien gespeichert, auf die wir keinen Zugriff haben. Details zur Datenverarbeitung durch Netlify finden Sie in deren <a href="https://www.netlify.com/privacy/" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a> und in der <a href="https://www.netlify.com/gdpr-ccpa" target="_blank" rel="noopener noreferrer">GDPR/CCPA-Verpflichtung</a>.</p>
        <p>Netlify erhebt folgende Daten: IP-Adresse, Datum und Uhrzeit der Anfrage, übertragene Datenmenge, Zugriffsstatus und die Website, von der der Nutzer auf unsere Seite gelangt ist (Referrer URL). Diese Daten werden für 30 Tage gespeichert. Die Speicherung dient der Sicherstellung der Website-Funktionalität, Optimierung und Sicherheit. Die Rechtsgrundlage hierfür ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO.</p>
        <p>Wenn Sie unser Kontaktformular verwenden, überträgt Netlify die von Ihnen eingegebenen Daten (Name, E-Mail-Adresse, Telefonnummer und Nachricht) sicher und verschlüsselt an unsere angegebene E-Mail-Adresse. Netlify ist als Auftragsverarbeiter gemäß Art. 28 DSGVO verpflichtet, Ihre Daten nur gemäß unseren Anweisungen zu verarbeiten und geeignete Schutzmaßnahmen zu treffen.</p>

        <h2>4. Verwendung von Google Fonts</h2>
        <p>
          Unsere Website verwendet Google Fonts, einen Dienst der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) zur einheitlichen Darstellung von Schriftarten. Beim Aufruf unserer Website werden die benötigten Schriftarten von den Servern von Google in Ihren Browser geladen.
        </p>
        <p>
          Dabei wird unter anderem Ihre IP-Adresse an Google übermittelt. Diese Datenübertragung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO, da wir ein berechtigtes Interesse an einer ansprechenden, einheitlichen und funktionalen Darstellung unserer Website haben.
        </p>
        <p>
          Google Fonts erhebt in der Regel keine Cookies oder speichert keine personenbezogenen Daten außer der IP-Adresse. Weitere Informationen zur Datenverarbeitung durch Google finden Sie in der <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Datenschutzerklärung von Google</a> und unter  <a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener noreferrer">Google Fonts FAQ</a>.
        </p>

        
        <h2>5. Links zu externen Anbietern</h2>
        <p>Unsere Website enthält Links zu externen Webseiten. Wir haben keinen Einfluss auf die Einhaltung der Datenschutzbestimmungen durch diese Anbieter. Bitte prüfen Sie deren Datenschutzerklärungen.</p>
        <h3>Google Maps</h3>
        <p>Unsere Website verwendet Google Maps für interaktive Karten und Wegbeschreibungen. Beim Laden der Karte wird Ihre IP-Adresse an Google übermittelt. Weitere Informationen finden Sie in der <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Datenschutzerklärung von Google</a>. Wir haben keinen Einfluss auf die Datenverarbeitung durch Google. Sie können die Nutzung der Kartenfunktion vermeiden, indem Sie diesen Bereich unserer Website nicht aufrufen.</p>
        <h3>WhatsApp-Button</h3>
        <p>Der WhatsApp-Button auf unserer Website leitet Sie zur WhatsApp-Webseite oder -App weiter. Dabei werden Ihre Daten, insbesondere Ihre Telefonnummer und eventuell Ihre Nachrichten, an WhatsApp übermittelt. Weitere Informationen finden Sie in der <a href="https://www.whatsapp.com/legal/" target="_blank" rel="noopener noreferrer">Datenschutzerklärung von WhatsApp</a>. Wenn Sie diesen Button nicht nutzen möchten, vermeiden Sie einfach dessen Verwendung.</p>
        <h3>Soziale Medien</h3>
        <p>Wir bieten Links zu unseren Social-Media-Profilen auf Instagram und TikTok an. Beim Klick auf diese Links werden Sie zu den jeweiligen Seiten weitergeleitet. Informationen zur Datenverarbeitung auf diesen Plattformen finden Sie in den Datenschutzerklärungen von:</p>
        <ul className="datenschutz-centered-list">
          <li><a href="https://help.instagram.com/519522125107875" target="_blank" rel="noopener noreferrer" className="centered-list-item">Instagram</a></li>
          <li><a href="https://www.tiktok.com/legal/privacy-policy?lang=de" target="_blank" rel="noopener noreferrer" className="centered-list-item">TikTok</a></li>
        </ul>

        <p>Wir haben keinen Einfluss auf die Datenverarbeitung durch diese Plattformen. Bei Fragen wenden Sie sich bitte direkt an die Plattformen oder lesen deren Datenschutzerklärungen.</p>
        
        <h2>6. Kontaktmöglichkeiten</h2>
        <p>Sie können uns auf verschiedene Weisen kontaktieren:</p>
        <h3>Kontaktformular</h3>
        <p>Wenn Sie unser Kontaktformular nutzen, werden Ihre Angaben (Name, E-Mail-Adresse, Telefonnummer, Nachricht) an unseren Hosting-Provider Netlify übermittelt. Netlify leitet diese Daten sicher und verschlüsselt an unsere E-Mail-Adresse weiter. Details zur Datenverarbeitung durch Netlify finden Sie in deren <a href="https://www.netlify.com/privacy/" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a> und in der <a href="https://www.netlify.com/gdpr-ccpa/" target="_blank" rel="noopener noreferrer">GDPR/CCPA-Verpflichtung</a>.</p>
        <h3>Telefonische Kontaktaufnahme</h3>
        <p>Durch Klicken auf die „Jetzt anrufen“-Schaltfläche wird ein Anruf an die angegebene Telefonnummer initiiert. Keine zusätzlichen Daten werden erfasst, außer Sie teilen uns Ihre Nummer während des Gesprächs mit.</p>
        <h3>E-Mail-Kontakt</h3>
        <p>Ein Klick auf den E-Mail-Link öffnet Ihren E-Mail-Client und erstellt eine E-Mail an uns. Ihre E-Mail-Adresse und die enthaltenen Informationen werden übermittelt. Wir verwenden diese Daten nur zur Beantwortung Ihrer Anfrage. Beachten Sie bitte, dass die E-Mail-Kommunikation Sicherheitslücken aufweisen kann, da ein vollständiger Schutz der Datenübertragung nicht gewährleistet werden kann.</p>
        <h2>7. Websiteentwicklung</h2>
        <p>Diese Webseite wurde erstellt von:</p>
        <p>
          <strong>Gammachiis Duguma</strong><br />
          gammachiis96@web.de<br />
        </p>
        <p>Für Anfragen zur Websiteentwicklung können Sie mich unter den oben genannten Kontaktdaten erreichen.</p>








      </div>



    </div><Footer isBlurred={isBlurred}/></>
  );
}

export default Datenschutz;
