import React, { useState, useEffect } from 'react';
import './Slideshow.css';

const smallImages = [
  `${process.env.PUBLIC_URL}/kleinBild2.jpg`,
  `${process.env.PUBLIC_URL}/kleinBild1.jpg`,
];

const largeImages = [
  `${process.env.PUBLIC_URL}/grossBild3.jpg`,
  `${process.env.PUBLIC_URL}/grossBild1.jpg`,
];

function Slideshow({ isBlurred }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const updateImages = () => {
      if (window.innerWidth < 768) {
        setImages(smallImages);
      } else {
        setImages(largeImages);
      }
    };

    // Run on initial load
    updateImages();

    // Add event listener to update images on window resize
    window.addEventListener('resize', updateImages);

    return () => {
      window.removeEventListener('resize', updateImages);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // Change image every 4 seconds

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className={`slideshow ${isBlurred ? 'blurred' : ''}`}>
        <div className="slideshow-container">
      <div className="slideshow-wrapper">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slideshow-slide ${index === currentImageIndex ? 'slideshow-slide-active' : ''}`}
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>
      <div className="slideshow-slogan">
        <p>Gemeinsam erreichen wir Ihre Gesundheitsziele</p>
      </div>
      <a href="/kontakt" className="slideshow-btn">
        Termin vereinbaren
      </a>
    </div>

    </div>
    
  );
}

export default Slideshow;
